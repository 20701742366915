import { post } from '@/utils/request';

export const treeselect = async data => {
  return await post('/appdealer/api/category/treeselect', data);
}

export const allListPlant = async data => {
    return await post('/appdealer/api/category/plants', data);
}

export const articleList = async data => {
    return await post('/appdealer/api/article/agro/list', data);
}

export const articleSearch = async data => {
    return await post('/appdealer/api/category/search', data);
}

export const articleInfo = async data => {
    return await post('/appdealer/api/agro/get_infoById', data);
}

export const articleAgroInfo = async data => {
    return await post('/appdealer/api/agro/getArticleAgro', data);
}
