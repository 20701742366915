<template>
    <div>
<!--        <FilterWrap
                v-show="showFilter"
                v-model="filterObj"
                @reset="filterObj ={}"
                @confirm="filterList"
        />-->
        <div class="dashboard" v-show="!showFilter">
            <div class="search-wrap">
                <SearchWrap v-model="searchTotal" @search="resetList"></SearchWrap>
                <div class="sort-wrap">
                    <img
                            src="@/assets/images/common/filter.png"
                            alt=""
                            @click="showFilterDialog"
                    />
                </div>
            </div>
            <Tabs swipeable swipe-threshold="1" @change="tabClick" style="padding-top: 10px;">
                <Tab
                        v-for="(item) in data"
                        :key="item.PLANT"
                        :title="item.PLANT"
                        :name="item.PLANT"
                >
<!--                    <div class="item-tag">
                      <span class="van-sku-row__item"
                            v-for="(children) in item.children"
                            :key="children.id"
                            :class="{ active: children.ancestors === ancestors }"
                            @click="tagClick(children.ancestors)"
                      >
                        <div class="van-sku-row__item-name">{{children.label}}</div>
                      </span>
                    </div>-->
                    <div
                            v-for="(item) in itemList"
                            :key="item.id"
                            class="item-wrap"
                            @click="handleClick(item)"
                    >

                        <img :src="item.imgUrl" alt=""/>
                        <div class="title">{{ item.title }}</div>
                        <div class="catename">
                            <tag class="van-tag van-tag--round van-tag--warning">{{ item.cateName }}</tag>
                        </div>
                        <div class="actions">
                            <span class="time">{{ item.createTime }}</span>
                            <span class="scannum"><img class="team-icon" src="../../../assets/images/home/view.png"/>{{ item.scanNum }}人阅读</span>
                        </div>

                    </div>
                </Tab>
            </Tabs>
            <infinite-loading ref="infinite" @infinite="infiniteHandler" style="margin-top:10px;font-size:14px">
                <span slot="no-more">
                  暂无更多
                </span>
            </infinite-loading>
        </div>

      <Tabbar />
    </div>
</template>
<script>
    import {allListPlant, articleList} from '@/api/agro';
    import SearchWrap from "@/components/common/search";
    import {Tabs, Tab, Tag} from 'vant';
    // import FilterWrap from "./Filter";
    import InfiniteLoading from "vue-infinite-loading";
    import Tabbar from "@/components/common/tabbar";


    export default {
        components: {
            Tab,
            Tabs,
            Tabbar,
            SearchWrap,
            // FilterWrap,
            Tag,
            InfiniteLoading
        },
        data() {
            return {
                data: [],
                itemList: [],
                ancestors: '',
                plant: '',
                searchTotal: '',
                showFilter: false,
                filterObj: {},
                pageNo: 1,
                pageSize: 5,
                total: 0,
            }
        },
        mounted() {
            this.getTreeselect();

        },
        activated() {
          this.ancestors = window.localStorage.getItem("agroDetailFilterAncestors");
          this.resetList();
        },
        methods: {
            showFilterDialog() {
                /*this.showFilter = true;*/
              this.$router.push({
                name: 'AgroDetailFilter',
               /* params: {
                  data: link
                }*/
              })

            },
            resetList() {
                this.showFilter = false;
                this.$refs.infinite._events["$InfiniteLoading:reset"][0]();
                this.itemList = [];
                this.pageNo = 1;
                this.total = 0;

            },
            filterList(){
                this.showFilter = false;
                this.resetList();
            },
            async infiniteHandler($state) {
                try {
                    await this.articleList(this.plant);
                } catch (err) {
                    //
                }
                $state.loaded();
                this.pageNo += 1;
                if (this.total - this.itemList.length === 0) {
                    $state.complete();
                }
            },
            handleClick(link) {
              var id = link.id;
              this.$router.push({
                name: "AgroInfoWeb",
                params: { id },
              });
                /*this.$router.push({
                    name: 'AgroInfo',
                    params: {
                        data: link
                    }
                })*/
            },
            async tabClick(name) {
                this.plant = name;
                this.resetList();
            },
            async getTreeselect() {
                const res = await allListPlant({});
                this.data = res.data;
                if (this.data.length > 0) {
                    const plant = this.data[0].PLANT;
                    this.plant = plant;
                    // this.articleList(plant)
                }
            },
            async articleList(searchPlant) {
                const searchTotal = this.searchTotal;
                const plant = searchPlant || this.plant;
                const res = await articleList({
                    "ancestors":this.ancestors,
                    "plant":plant,
                    "searchTotal":searchTotal,
                    "page.pageNo": this.pageNo,
                    "page.pageSize": this.pageSize,
                });
                this.itemList = this.itemList.concat(res.data || []);
                // this.itemList = res.data;
                this.total = (res.page && res.page.total) || 0;
            }

        }
    }
</script>

<style lang="scss" scoped>
    .dashboard {
        width: 351px;
        margin: 12px auto 0;
        background: #FFFFFF;
        box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
        border-radius: 10px;
        padding: 12px 12px 9px;
        box-sizing: border-box;
        .title {
            font-size: 12px;
            font-weight: 500;
            line-height: 17px;
            color: #2C2C2C;
            padding-bottom: 10px;
            text-align: left;
        }
        .item-wrap {
            flex: 0 0 25%;
            padding-left: 5px;
            padding-top: 15px;
            .title {
                font-size: 14px;
                font-weight: 500;
                color: #2c2c2c;
            }
            .catename {
                font-size: 12px;
                padding-left: 20px;
                line-height: 14px;
                color: #2c2c2c;
                text-align: left;
            }
            .time {
                display: inline-block;
                font-size: 12px;
                line-height: 14px;
                color: #2c2c2c;
            }
            .scannum {
                font-size: 12px;
                line-height: 14px;
                color: #2c2c2c;
            }
            img {
                width: 65px;
                height: 57px;
                float: left;
                object-fit: cover;
                margin-bottom: 6px;
                padding-right: 6px;
            }
        }
        .item-tag {
            height: 70px;
            text-align: left;
            overflow: scroll;
            padding-top: 10px;
        }
        .actions {
            margin-top: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .team-icon {
                width: 12px;
                height: 12px;
                margin-right: 2px;
            }

        }
        .active {
            color: #ee0a24;
        }
        .search-wrap {
            padding: 10px 24px 10px 12px;
            background-color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .sort-wrap {
                flex-shrink: 0;
                img {
                    height: 14px;
                    width: auto;
                    margin-left: 20px;
                }
            }
        }

    }
</style>
