<template>
  <div class="shidanli-business">
    <HeaderTabs :tabs="tabs" :activeName="activeName" @click="handleTabClick">
      <div class="navigate-wrap" @click="naivgateAdd">
        <span>添加</span>
        <img src="../../assets/images/common/add.png" alt="" />
      </div>
    </HeaderTabs>
    <div class="search-wrap">
      <SearchWrap v-model="searchTotal" @search="resetList"></SearchWrap>
    </div>
    <div
      class="member-container"
      v-for="(member, idx) in list"
      :key="member.userId"
    >
      <div class="member" @click="navigateDetailPage(member.userId)">
        <div class="avatar" :style="{ backgroundColor: colorList[idx % 4] }">
          {{ (member.nickName || "").slice(0, 1) }}
        </div>
        <div class="info">
          <div class="nick">
            {{ member.nickName }}
            <span v-if="member.userType !== '00'" class="tag">自有</span>
          </div>
          <div class="phone">{{ member.phonenumber }}</div>
        </div>
        <div class="button-container">
          <CustomButton
            class="button"
            size="mini"
            @click.stop="handleTask(member)"
            >下达任务</CustomButton
          >
          <CustomButton
            class="button"
            size="mini-plain"
            @click.stop="handleVisit(member.userId)"
            >拜访任务</CustomButton
          >
          <CustomButton
            v-if="member.userType !== '00'"
            class="button delete-button"
            size="mini-plain"
            @click.stop="handleDelete(member.userId)"
            >删除</CustomButton
          >
        </div>
      </div>
    </div>
    <infinite-loading ref="infinite" @infinite="infiniteHandler">
      <span slot="no-more">
        暂无更多
      </span>
    </infinite-loading>
    <!-- <img
      class="add-icon"
      src="../../assets/images/tabbar/add.png"
      alt=""
      @click="naivgateAdd"
    /> -->
  </div>
</template>
<script>
import HeaderTabs from "@/components/header/headerTabs";
import { Dialog } from "vant";
import SearchWrap from "@/components/common/search";
import CustomButton from "@/components/common/button/CustomButton.vue";
import InfiniteLoading from "vue-infinite-loading";
import { shidanliBusinessApi } from "@/utils/api.js";
export default {
  components: {
    HeaderTabs,
    SearchWrap,
    CustomButton,
    InfiniteLoading,
  },
  data() {
    return {
      activeName: "空闲的成员",
      tabs: [{ title: "空闲的成员" }, { title: "执行任务中的成员" }],
      colorList: ["#FF6A36", "#03C714", "#335AFF", "#FF4A9C"],
      list: [],
      searchTotal: "",
      pageNo: 1,
      pageSize: 10,
      total: 0,
    };
  },
  activated() {
    this.resetList();
  },
  methods: {
    handleTabClick(tab) {
      this.activeName = tab;
      this.resetList();
    },
    // 搜索
    resetList() {
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]();
      this.list = [];
      this.pageNo = 1;
      this.total = 0;
    },
    async infiniteHandler($state) {
      try {
        await this.search();
      } catch (err) {
        //
      }
      $state.loaded();
      this.pageNo += 1;
      if (this.total - this.list.length === 0) {
        $state.complete();
      }
    },
    async search(clear) {
      if (clear) {
        this.resetList();
      }
      const response = await shidanliBusinessApi.salemanList({
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
        searchTotal: this.searchTotal,
        takeStatus: this.activeName === "空闲的成员" ? "1" : "0",
      });
      this.list = this.list.concat(response.data || []);
      this.total = (response.page && response.page.total) || 0;
    },
    naivgateAdd() {
      this.$router.push({ name: "ShidanliBusinessAdd" });
    },
    navigateDetailPage(id) {
      this.$router.push({
        name: "ShidanliBusinessMemberDetail",
        params: { id },
      });
    },
    handleTask(item) {
      this.$router.push({
        name: "TaskPublishTask",
        params: { sendUser: item },
      });
    },
    handleVisit(userId) {
      this.$router.push({ path: `/visit/list?userId=${userId}` });
    },
    async handleDelete(userId) {
      try {
        await Dialog.confirm({
          title: "提示",
          message: "确定要删掉这条数据吗?",
        });
        this.confirmDelete(userId);
      } catch (err) {
        //
      }
    },
    async confirmDelete(userId) {
      try {
        await shidanliBusinessApi.salemanDelete({ userId });
        this.$toast("删除成功");
        this.resetList();
      } catch (err) {
        this.$toast(err.message);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.shidanli-business {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  .navigate-wrap {
    position: absolute;
    top: 12px;
    right: 24px;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    align-items: center;
    span {
      flex-shrink: 0;
    }
    img {
      height: 14px;
      width: auto;
      margin-left: 4px;
    }
  }
  .search-wrap {
    padding: 10px 24px 10px 12px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sort-wrap {
      margin-left: 32px;
    }
  }
  .member-container {
    margin: 12px;
    padding: 12px;
    background-color: #ffffff;
    box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
    border-radius: 10px;
    .member {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      .avatar {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        line-height: 30px;
        text-align: center;
        color: #fff;
      }
      .info {
        margin-left: 8px;
        text-align: left;
        flex: 1;
        .nick {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: #2c2c2c;
          .tag {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 2px 6px;
            background: #ffe3e7;
            box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
            border-radius: 5px;
            font-size: 12px;
            color: #cd001f;
            transform: translateY(-2px);
          }
        }
        .phone {
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          color: #006efb;
        }
      }
      .button-container {
        display: flex;
        flex-direction: column;
        .button {
          width: 75px;
          padding-left: 0;
          padding-right: 0;
          margin-bottom: 4px;
        }
        .delete-button {
          position: absolute;
          bottom: 0;
          right: 80px;
        }
      }
    }
  }
  .add-icon {
    position: fixed;
    z-index: 10;
    right: 24px;
    bottom: 32px;
    width: 40px;
    height: 40px;
  }
}
</style>
