<template>
  <div class="filter-page">
    <Header class="mb-12" text="确定" @textClick="handleConfirm"/>
    <div class="item-tag" v-for="(children) in itemList " :key="children.id">
      <div class="item-tittle">{{children.label}}</div>
      <div class="item-content">
        <span class="van-sku-row__item"
              v-for="(grandson) in children.children" :key="grandson.id"
              :class="{ active: grandson.ancestors === ancestors }"
              @click="tagClick(grandson.ancestors)"
        >
          <div class="van-sku-row__item-name">{{grandson.label}}</div>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import {treeselect} from '@/api/agro';
import Header from '@/components/header/headerWithText/index.vue'

export default {
  components: {
    Header
  },
  data() {
    return {
      itemList:[],
      ancestors:''
    };
  },
  mounted() {
      this.getSearch();
  },
  activated() {
    if (!window.localStorage.getItem("agroDetailFilterAncestors")) {
      this.ancestors = '';
    }
  },
  methods: {
      async getSearch(){
          const res = await treeselect();
          this.itemList = res.data[0].children;
      },
      tagClick(ancestors) {
          if (this.ancestors == ancestors) {
            this.ancestors = '';
          } else {
            this.ancestors = ancestors;
            this.confirm(ancestors);
          }
      },
    reset() {
      this.itemList = {};
      this.$emit("input", {});
    },
    confirm(ancestors) {
      window.localStorage.setItem(
          "agroDetailFilterAncestors",
          ancestors
      );
      this.$router.go(-1);
    },
    handleConfirm() {
      window.localStorage.setItem(
          "agroDetailFilterAncestors",
          this.ancestors
      );
      this.$router.go(-1);
    }
  },
};
</script>
<style lang="scss" scoped>
.filter-page {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
}
.item-tag {
  width: 100%;
  .item-tittle {
    width: 80px;
    text-indent: 10px;
    line-height: 20px;
    border-left: 3px solid #ee0a24;
    margin: 0 0 0 5px;
  }
  .item-content {
    margin-left: 20px;
    text-align: left;
    .van-sku-row__item {
      width: 21%;
      margin-top: 10px;
      .van-sku-row__item-name {
        font-size: 14px;
      }
    }
    .active {
      color: red;
    }
  }

}
</style>
