<template>
  <div class="member-detail">
    <Header />
    <div class="info-wrap">
      <div class="remark"><b>注:</b>账号和手机号均可作为用户登录账号</div>
      <div class="member">
        <div class="avatar">
          {{ member.nickName.slice(0, 1) }}
        </div>
        <div class="person-info">
          <div class="name">
            <span>{{ member.nickName }}</span>
            <span class="remark">{{ member.remark }}</span>
          </div>
          <div class="username">账号:{{ member.userName }}</div>
          <div class="phone">手机:{{ member.phonenumber }}</div>
        </div>
        <div class="delete-icon" @click="handleDelete">
          <img src="../../assets/images/common/trash.png" alt="" />
          <span>删除</span>
        </div>
      </div>
      <div class="actions">
        <CustomButton size="mini" @click.stop="handleVisit"
          >拜访记录</CustomButton
        >
        <div>
          <CustomButton class="mr-10" size="mini-plain" @click.stop="toMapTrace"
            >轨迹查询</CustomButton
          >
          <CustomButton size="mini" @click.stop="handleTask"
            >下达任务</CustomButton
          >
        </div>
      </div>
    </div>
    <div class="summary-wrap">
      <div class="title"></div>
      <div class="items">
        <div class="item">
          <div class="count">{{ member.totalVisit }}</div>
          <div class="label">拜访数/单</div>
        </div>
        <div class="item">
          <div class="count">{{ member.totalTask }}</div>
          <div class="label">完成任务数/个</div>
        </div>
      </div>
    </div>
    <div class="task-wrap">
      <div class="tabs">
        <div
          class="tab"
          :class="{ active: activeName === '执行中的任务' }"
          @click="activeName = '执行中的任务'"
        >
          执行中的任务
        </div>
        <div
          class="tab"
          :class="{ active: activeName === '已完成任务' }"
          @click="activeName = '已完成任务'"
        >
          已完成任务
        </div>
      </div>
      <div class="items">
        <div
          v-for="task in tasks"
          :key="task.id"
          class="item mb-12"
          @click="handleTaskDetail(task.id)"
        >
          <div class="title"><i class="point" />{{ task.taskName }}</div>
          <div>
            <span class="label">发布人</span>
            <span class="content">{{ task.createByName }}</span>
          </div>
          <div>
            <span class="label">执行人</span>
            <span class="content">{{ task.taskReceiveName }}</span>
          </div>
          <div>
            <span class="label">下达任务时间</span>
            <span class="content">{{
              task.createTime || task.updateTime
            }}</span>
          </div>
          <div>
            <div class="label">任务描述</div>
            <div class="content">{{ task.taskContent }}</div>
          </div>
        </div>
      </div>
    </div>
    <infinite-loading ref="infinite" @infinite="infiniteHandler">
      <span slot="no-more">
        <div style="margin-top: 16px">暂无更多</div>
      </span>
    </infinite-loading>
  </div>
</template>
<script>
import Header from "@/components/header/index.vue";
import CustomButton from "@/components/common/button/CustomButton.vue";
import { shidanliBusinessApi } from "@/utils/api.js";
import { Dialog } from "vant";
import InfiniteLoading from "vue-infinite-loading";
export default {
  components: {
    Header,
    CustomButton,
    InfiniteLoading,
  },
  data() {
    return {
      activeName: "执行中的任务",
      member: { nickName: "" },
      tasks: [],
      pageNo: 1,
      pageSize: 5,
      total: 0,
    };
  },
  watch: {
    activeName() {
      this.resetList();
    },
  },
  activated() {
    this.resetList();
  },
  methods: {
    resetList() {
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]();
      this.tasks = [];
      this.pageNo = 1;
      this.total = 0;
    },
    async infiniteHandler($state) {
      await this.fetchTasks();
      $state.loaded();
      this.pageNo += 1;
      if (this.total - this.tasks.length === 0) {
        $state.complete();
      }
    },
    async fetchTasks() {
      await this.fetchDetail();
      let response;
      if (this.activeName === "执行中的任务") {
        response = await shidanliBusinessApi.taskList({
          "page.pageNo": this.pageNo,
          "page.pageSize": this.pageSize,
          taskReceive: this.member.userName,
          taskState: "0",
        });
      } else {
        response = await shidanliBusinessApi.taskList({
          "page.pageNo": this.pageNo,
          "page.pageSize": this.pageSize,
          taskReceive: this.member.userName,
          taskState: "1",
        });
      }
      this.tasks = this.tasks.concat(response.data || []);
      this.total = (response.page && response.page.total) || 0;
    },
    async fetchDetail() {
      const response = await shidanliBusinessApi.salemanDetail({
        userId: this.$route.params.id,
      });
      this.member = response.data;
    },
    handleTask() {
      this.$router.push({
        name: "TaskPublishTask",
        params: { sendUser: this.member },
      });
    },
    handleVisit() {
      this.$router.push({
        path: `/visit/list?userId=${this.$route.params.id}`,
      });
    },
    handleTaskDetail(id) {
      this.$router.push({
        path: `/task/detail/${id}`,
      });
    },
    toMapTrace() {
      this.$router.push({ name: "MapTrace", params: { user: this.member } });
    },
    async handleDelete() {
      try {
        await Dialog.confirm({
          title: "提示",
          message: "确定要删掉这条数据吗?",
        });
        this.confirmDelete();
      } catch (err) {
        //
      }
    },
    async confirmDelete() {
      try {
        await shidanliBusinessApi.salemanDelete({ userId: this.member.userId });
        this.$toast("删除成功");
        this.$router.push({ name: "ShidanliBusiness" });
      } catch (err) {
        this.$toast(err.message);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.member-detail {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  .info-wrap,
  .summary-wrap,
  .task-wrap {
    margin: 12px 12px 0;
    padding: 12px;
    background-color: #ffffff;
    box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
    border-radius: 10px;
  }
  .info-wrap {
    .remark{
      font-size: 12px;
      color: #cd001f;
      text-align: left;
    }
    .member {
      display: flex;
      align-items: center;
      position: relative;
      .avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        background: #ff6a36;
        border-radius: 50%;
        text-align: center;
        color: #fff;
        font-size: 18px;
      }
      .person-info {
        margin-left: 6px;
        padding: 6px 0 3px;
        text-align: left;
        .name {
          font-size: 14px;
          font-weight: 500;
          color: #2c2c2c;
          .remark {
            display: inline-block;
            margin-left: 14px;
            font-size: 14px;
            color: #cd001f;
          }
        }
        .username {
          font-size: 12px;
          color: #2c2c2c;
          margin-top: 5px;
        }
        .phone {
          font-size: 12px;
          color: #006efb;
          margin-top: 5px;
        }
      }
      .delete-icon {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #cd001f;
        position: absolute;
        right: 0;
        top: 0;
        img {
          height: 14px;
          width: auto;
          margin-right: 2px;
        }
        span {
          flex-shrink: 0;
        }
      }
    }
    .actions {
      margin-top: 12px;
      border-top: 1px solid #eaeaea;
      padding-top: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      ::v-deep .custom-btn {
        padding: 0 6px;
      }
      .mr-10 {
        margin-right: 10px;
      }
    }
  }
  .summary-wrap {
    .title {
      font-size: 12px;
      color: #2c2c2c;
      margin-bottom: 6px;
    }
    .items {
      display: flex;
      .item {
        flex: 0 0 50%;
        .count {
          font-size: 18px;
          font-weight: 500;
          color: #2c2c2c;
          margin-bottom: 4px;
        }
        .label {
          font-size: 13px;
          color: #cecece;
        }
      }
    }
  }
  .task-wrap {
    padding: 0;
    .tabs {
      display: flex;
      align-items: center;
      padding: 12px 12px 8px;
      justify-content: space-between;
      font-size: 12px;
      color: #2c2c2c;
      border-bottom: 1px solid #eaeaea;
      .tab.active {
        color: #cd001f;
      }
    }
    .items {
      .item {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #eaeaea;
        .title {
          font-size: 14px;
          font-weight: 500;
          color: #2c2c2c;
          margin-bottom: 6px;
          display: flex;
          align-items: center;
          .point {
            width: 4px;
            height: 4px;
            background: #cd001f;
            border-radius: 50%;
            margin-right: 4px;
          }
        }
        .label {
          font-size: 14px;
          color: #818181;
          margin-bottom: 6px;
          margin-right: 6px;
        }
        .content {
          font-size: 14px;
          color: #2c2c2c;
          margin-bottom: 6px;
        }
      }
    }
  }
}
</style>
