<template>
  <div>
    <KnowledgeFilter
      v-show="isFilter"
      v-model="filterValue"
      @confirm="resetList"
    />
    <div v-show="!isFilter" class="shidanli-business-knowledge">
      <HeaderNavigation label="确定" icon="" path="back" />
      <div class="search-wrap">
        <SearchWrap v-model="searchTotal" @search="resetList"></SearchWrap>
        <div class="sort-wrap" @click="isFilter = true">
          品牌
          <img src="../../assets/images/common/arrow-down.png" alt="" />
        </div>
      </div>
      <div class="list-wrap">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="item-wrap"
          @click="navigateDetailPage(item)"
        >
          <img :src="item.largerUrl" alt="" />
          <div class="item-wrap title">{{ item.materialName }}</div>
        </div>
        <div v-if="list.length % 4 === 3" class="item-wrap empty"></div>
        <div v-if="list.length % 4 === 2" class="item-wrap empty"></div>
        <div v-if="list.length % 4 === 2" class="item-wrap empty"></div>
      </div>
      <infinite-loading ref="infinite" @infinite="infiniteHandler">
        <span slot="no-more">
          暂无更多
        </span>
      </infinite-loading>
    </div>
  </div>
</template>
<script>
import SearchWrap from "@/components/common/search";
import HeaderNavigation from "@/components/header/headerWithNavigation";
import { fetchProductList } from "@/api/product";
import KnowledgeFilter from "./components/KnowledgeFilter";
import InfiniteLoading from "vue-infinite-loading";
export default {
  components: {
    SearchWrap,
    HeaderNavigation,
    KnowledgeFilter,
    InfiniteLoading,
  },
  data() {
    return {
      searchTotal: "",
      filterValue: {},
      list: [],
      isFilter: false,
      pageNo: 1,
      pageSize: 20,
      total: 0,
    };
  },
  // mounted() {
  //   this.search();
  // },
  methods: {
    resetList() {
      this.isFilter = false;
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]();
      this.list = [];
      this.pageNo = 1;
      this.total = 0;
    },
    async infiniteHandler($state) {
      try {
        await this.search();
      } catch (err) {
        //
      }
      $state.loaded();
      this.pageNo += 1;
      if (this.total - this.list.length === 0) {
        $state.complete();
      }
    },
    async search() {
      const { brandId, materialClass, materialName } = this.filterValue;
      const response = await fetchProductList({
        brandId: brandId === "无" ? "" : brandId || "", // 品牌
        materialClass: materialName === "全部" ? "" : materialClass || "", // 分类
        materialName: this.searchTotal,
        // recoRank: "1", // 是否在首页推荐显示
        // newRank: "1", // 是否在首页新产品显示
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
        searchTotal: this.searchTotal,
      });
      this.list = this.list.concat(response.data || []);
      this.total = (response.page && response.page.total) || 0;
    },
    navigateDetailPage(item) {
      window.localStorage.setItem("KnowledgeDetail", JSON.stringify(item));
      this.$router.push({
        name: "ShidanliBusinessKnowledgeDetail",
        params: { id: item.id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.shidanli-business-knowledge {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  .search-wrap {
    padding: 10px 24px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sort-wrap {
      margin-left: 32px;
      font-size: 12px;
      color: #2c2c2c;
      flex-shrink: 0;
      img {
        width: 16px;
        height: auto;
        transform: translateY(2px);
      }
    }
  }
  .list-wrap {
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    box-sizing: border-box;
    & * {
      box-sizing: border-box;
    }
    .item-wrap {
      margin-bottom: 12px;
      flex: 0 0 25%;
      padding: 0 8px;
      .title {
        font-size: 10px;
        line-height: 14px;
        color: #2c2c2c;
        text-align: left;
      }
      img {
        width: 65px;
        height: 57px;
        object-fit: cover;
        margin-bottom: 6px;
      }
    }
  }
}
</style>
